import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaGalacticRepublic, FaHornbill, FaReact } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				Про Нас | Woods
			</title>
			<meta name={"description"} content={"Точний вибір для кожної гри та тренування"} />
			<meta property={"og:title"} content={"Про Нас | Woods"} />
			<meta property={"og:description"} content={"Точний вибір для кожної гри та тренування"} />
			<meta property={"og:image"} content={"https://ua.woodsua.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://ua.woodsua.com/img/soccer-icon-png-1.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Про елітні футівки
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
					width="75%"
				>
					Відкрийте для себе мандрівку та відданість Woods, пропонуючи кураторну футбольну техніку, тренери та аксесуари для ринку.Дізнайтеся про наші чіткі переваги та чому ми довірений вибір для багатьох.
					<br />
					<br />
					У Woods наша місія проста: оснащувати арени футбольним обладнанням, тренерами та аксесуарами вищого рівня, роблячи процес безшовним та забезпечуючи якість на кожному кроці.Хоча ми не виробники, наші вибагливі методи очей та ретельні пошуки гарантують, що ми лікуємо лише найкращі продукти для нашої клієнтури.Обслуговуючи виключно футбольний ринок, наше зобов’язання непохитне - розширення можливостей та оснащення кожного тренажерного залу та суду, щоб вони були найкращими.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaGalacticRepublic}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Курація досконалості
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Поки ми не виробляємо, ми вдосконалюємо мистецтво відбору.Тільки найкращі продукти, від надійних брендів та перевірених постачальників, потрапляють до нашої колекції.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaHornbill}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Спеціалізований фокус
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Наш підхід, орієнтований на футбол, означає, що ми розуміємо конкретні потреби та потреби спортзалів та судів.Ми не просто продаємо - ми партнери у вашому успіху.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaReact}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Прозорі ціни
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						З елітними підніжжями не існує прихованих зарядів чи накладних витрат.Наше конкурентне ціноутворення відображає нашу прихильність до надання цінності.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://ua.woodsua.com/img/2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Чому Woods?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Виберіть Нас - це інвестиція в якість, надійність та партнерство. Ми піднімаємося вище конкурентів, оскільки ми надаємо пріоритет розуміння ваших унікальних потреб та пропонуючи рішення, які підходять. З Woods ви не просто купуєте продукцію - ви підробляєте стосунки з командою, яка так само пристрасно ставиться до футболу, як і ви.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 44px 12px 44px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-indigo"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Відвідай нас
				</Link>
			</Box>
		</Section>
		<Components.Footer13 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});